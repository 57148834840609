<template>
  <div @click="resultShow = false">
    <form-wizard color="#08308B" :title="null" :subtitle="null" layout="vertical" finish-button-text="Submit"
      back-button-text="Previous" next-button-text="Next" class="wizard-vertical mb-3" @on-complete="formSubmitted">
      <!-- Main Data-->
      <tab-content :before-change="validationForm">
        <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">General Data</h5>
              <small class="text-muted"> Enter Your Product Main Data </small>
            </b-col>
            <!-- report serial number -->
            <b-col md="4">
              <b-row>
                <b-col cols="11">
                  <b-form-group label="Report Serial Number" label-for="report serial number">
                    <validation-provider #default="{ errors }" name="report serial number" rules="required">
                      <b-form-input list="my-list-id" placeholder="Report Serial Number" id="report-serial"
                        class="taxDesc" name="report-serial" v-model="productData.serialnumber" autocomplete="off"
                        :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="1">
                  <b-button style="
                      padding: 7px;
                      width: 35px;
                      height: 35px;
                      float: right;
                    " v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                    class="btn-icon rounded-circle mt-2 ml-auto" @click="searchResultButton">
                    <feather-icon icon="SearchIcon" style="width: 15px; height: 15px" />
                  </b-button>
                </b-col>
                <b-alert variant="warning" style="margin: 0.438rem 1rem" :show="noResult">
                  <div class="alert-body">No Result Found.</div>
                </b-alert>
                <b-col class="mt-2" style="
                    position: absolute;
                    z-index: 9999;
                    padding: 0.438rem 1rem;
                    top: 38px;
                    height: 380px !important;
                    overflow: scroll;
                    scroll-padding: 0px !important;
                    padding-right: 0;
                    background-color: #fff;
                  " v-if="resultShow" cols="12">
                  <b-list-group>
                    <b-list-group-item v-b-modal.modal-prevent-closing v-for="(item, index) in serialsNumber"
                      :key="index" @click="productData.serialnumber = item.name">
                      <feather-icon icon="" class="mr-75" />
                      <p style="font-weight: 600">
                        {{ item.name }}
                      </p>
                    </b-list-group-item>
                    <infinite-loading @infinite="searchResult"></infinite-loading>
                  </b-list-group>
                </b-col>
              </b-row>
            </b-col>
            <!-- Brand -->
            <b-col md="4">
              <validation-provider #default="{ errors }" name="brand" rules="required">
                <b-form-group label="Brand" label-for="Brand" :state="errors.length > 0 ? false : null">
                  <v-select id="brand" v-model="brandSelect" :state="brandSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="brandOptions" :selectable="(option) => !option.value.includes('select_value')
                      " label="text" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Item -->
            <b-col md="4">
              <validation-provider #default="{ errors }" name="item" rules="required">
                <b-form-group label="Item" label-for="Item" :state="errors.length > 0 ? false : null">
                  <v-select id="item" v-model="itemSelect" :disabled="brendSe"
                    :state="itemSelect === null ? false : true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="itemOptions.map(item => ({ value: item.id, text: item.name }))" label="text" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <tab-content :before-change="validationForm2">
        <validation-observer ref="accountRules2" tag="form">
          <b-row v-if="brandSelect != null && itemSelect != null" class="mb-5">
            <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center mb-2 mb-md-0">
              <div class="d-flex align-items-center justify-content-center">
                <b-img :src="itemSelect.cover" height="250px" class="product-img" />
              </div>
            </b-col>
            <b-col md="6" class="mt-auto mb-auto">
              <!-- Store Name -->
              <h6>
                <span style="color: #0d6efd">Store:</span>
                <b-badge variant="primary">{{ vendorSelect.text }}</b-badge>
              </h6>
              <br />
              <!-- Product Name -->
              <h6>
                <span style="color: #0d6efd">Name:</span>
                <b-badge variant="primary">{{ itemSelect.name }}</b-badge>
              </h6>
              <br />
              <b-card-text class="item-company mb-0">
                <h6>
                  <span style="color: #0d6efd">Brand:</span>
                  <b-badge variant="primary">{{ brandSelect.text }}</b-badge>
                </h6>
              </b-card-text>
              <br />
              <b-card-text v-if="categorySelect != null" class="item-company mb-0">
                <h6>
                  <span style="color: #0d6efd">Category:</span>
                  <b-badge variant="primary">
                    {{ categorySelect.text }}
                  </b-badge>
                </h6>
              </b-card-text>
              <br />

              <!-- Product Short Description -->
              <h6 v-if="
                itemSelect.short_description != null &&
                itemSelect.short_description != ''
              ">
                <span style="color: #0d6efd">Short Description:</span>
              </h6>
              <b-card-text class="ml-3">
                <div v-html="itemSelect.short_description"></div>
              </b-card-text>
              <br />
              <!-- Product  Description -->
            </b-col>
          </b-row>
          <b-row>
            <!-- Category -->
            <b-col md="12" v-if="itemSelect != null">
              <h5 v-if="
                itemSelect.short_description != null &&
                itemSelect.short_description != ''
              ">
                <span style="color: #0d6efd">Description:</span>
              </h5>
              <b-card-text class="ml-3">
                <div v-html="itemSelect.description"></div>
              </b-card-text>
            </b-col>
            <b-col cols="12" class="mt-5">
              <b-card title="Attributes and Values">
                <b-row>
                  <b-col cols="12">
                    <div>
                      <!-- Row Loop -->
                      <b-row v-for="(item, index) in productData.attributes" :key="index">
                        <!-- Attrbuites -->
                        <b-col md="2" class="d-flex justify-content-center align-items-center">
                          <!-- <validation-provider #default="{ errors }" name="attribute" rules="required">
                                                            <b-form-group label="Attribute" label-for="attribute" :state="errors.length > 0 ? false:null">
                                                                <v-select disabled :id="'attributes' + index" v-model="attrSelect[index]" :clearable="false" @input="getValues(index,attrSelect[index].value)" :state="attrSelect[index] === null ? false : true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="attr2Options"
                                                                    :selectable="option => ! option.value.includes('select_value')" label="text" />
                                                                <small class="text-danger">{{ errors[0] }}</small>
                                                            </b-form-group>
                                                        </validation-provider> -->
                          <p class="text-primary mb-0 font-weight-bold h4">
                            {{ attrSelect[index].text }}
                          </p>
                        </b-col>
                        <!-- <b-col v-if="productData.is_new == 'new'" md="4">
                              <validation-provider
                                #default="{ errors }"
                                name="values"
                                rules="required"
                              >
                                <b-form-group
                                  label="Values"
                                  label-for="values"
                                  :state="errors.length > 0 ? false : null"
                                >
                                  <v-select
                                    multiple
                                    :id="'values' + index"
                                    v-model="valuesSelect[index]"
                                    :clearable="false"
                                    :state="
                                      valuesSelect[index] === null ? false : true
                                    "
                                    :dir="
                                      $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                    "
                                    :options="valuesOptions[index]"
                                    :selectable="
                                      (option) =>
                                        !option.value.includes('select_value')
                                    "
                                    label="text"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                              </validation-provider>
                            </b-col> -->
                        <b-col md="4">
                          <div v-if="attrSelect[index].slug == 'color'">
                            <validation-provider #default="{ errors }" name="values" rules="required">
                              <b-form-group label="Value" label-for="values" :state="errors.length > 0 ? false : null">
                                <v-select :id="'values' + index" v-model="valuesSelect[index]" :clearable="false"
                                  :state="valuesSelect[index] === null ? false : true
                                    " :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                      " :options="valuesOptions[index]" :selectable="(option) =>
                                      !option.value.includes('select_value')
                                      " label="text" />
                                <small class="text-danger">
                                  {{ errors[0] }}
                                </small>
                              </b-form-group>
                            </validation-provider>
                          </div>
                          <div v-else-if="attrSelect[index].slug == 'sim-card'">
                            <validation-provider #default="{ errors }" name="values" rules="required">
                              <b-form-group label="Value" label-for="values" :state="errors.length > 0 ? false : null">
                                <v-select :id="'values' + index" v-model="valuesSelect[index]" :clearable="false"
                                  :state="valuesSelect[index] === null ? false : true
                                    " :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                      " :options="valuesOptions[index]" :selectable="(option) =>
                                      !option.value.includes('select_value')
                                      " label="text" />
                                <small class="text-danger">
                                  {{ errors[0] }}
                                </small>
                              </b-form-group>
                            </validation-provider>
                          </div>
                          <p class="text-secondary h5 mb-0" v-else>
                            {{ valuesSelect[index].text }}
                          </p>
                        </b-col>
                        <!-- Remove Button -->
                        <!-- <b-col lg="2" md="3" class="mb-50">
                                                                                                                        <b-button v-if="index > 0" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" @click="taxonomyremoveItem(index)">
                                                                                                                            <feather-icon icon="XIcon" class="mr-25" />
                                                                                                                            <span>Remove</span>
                                                                                                                        </b-button>
                                                                                                                    </b-col> -->
                        <b-col cols="12">
                          <hr />
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <!-- add new button -->
                  <!-- <b-col sm="2">
                                                                                                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" block @click="taxonomyrepeateAgain">
                                                                                                                <feather-icon icon="PlusIcon" class="mr-25" />
                                                                                                                <span> {{$t('Add_Product.Add_New')}}   </span>
                                                                                                            </b-button>
                                                                                                        </b-col> -->
                </b-row>
                <b-button v-if="itemSelect != null" class="mt-2" @click="generate" block variant="success">
                  Generate
                </b-button>
              </b-card>
            </b-col>
            <b-col cols="12" v-if="varints.length > 0">
              <b-card title="Variants" ref="varintsHeight">
                <b-row>
                  <b-col cols="12">
                    <div>
                      <!-- Row Loop -->
                      <b-row v-for="(item, index) in varints" :key="index">
                        <b-col cols="12">
                          <b-row>
                            <b-col cols="12" class="">
                              <b-row>
                                <!-- price button -->
                                <b-col md="2" class="d-flex justify-content-center align-items-center">
                                  <b-button variant="warning" @click="setPrice(item, index)"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-prevent-closing>
                                    Add Price
                                  </b-button>
                                </b-col>
                                <!-- varint quantity -->
                                <b-col md="2" class="d-flex justify-content-center align-items-center">
                                  <b-form-group label="Quantity" label-for="quantity">
                                    <validation-provider #default="{ errors }" name="quantity"
                                      rules="required|regex:^[0]*[1-9][0-9]*$">
                                      <b-form-input :id="'quantity' + index" :disabled="productData.is_new == 'used'
                                        ? true
                                        : false
                                        " min="0" type="number" v-model="item.vquantity" :state="errors.length > 0 ? false : null
                                          " />
                                      <small class="text-danger">
                                        {{ errors[0] }}
                                      </small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="4" v-show="item.salePrice > 0 && item.salePrice != null
                                  ">
                                  <p>
                                    Original Price:
                                    {{ item.voriginal_price }}
                                    EGP
                                  </p>
                                  <p v-show="item.offerBDel.amount != 0 &&
                                    item.offerBDel.amount != '' &&
                                    item.offerBDel.amount != null
                                    ">
                                    Offer:
                                    <b-badge varaint="primary" v-if="
                                      item.offerBDel.type == 'fixed_price'
                                    ">
                                      {{ item.offerBDel.amount }}
                                      EGP
                                    </b-badge>
                                    <b-badge variant="primary" v-else>
                                      {{ item.offerBDel.amount }}%
                                    </b-badge>
                                  </p>
                                  <p>
                                    Price:
                                    {{ item.salePrice }}
                                    EGP
                                  </p>
                                  <p>
                                    Profit:
                                    {{ item.profit }}
                                    EGP
                                  </p>
                                </b-col>
                                <b-col cols="12">
                                  <b-row>
                                    <!-- warranty -->
                                    <b-col md="6">
                                      <validation-provider #default="{ errors }" name="Warranty" rules="required">
                                        <b-form-group label="Warranty" label-for="warranty" :state="errors.length > 0 ? false : null
                                          ">
                                          <v-select id="warranty" v-model="warrantySelect[index]" :state="warrantySelect[index] === null
                                            ? false
                                            : true
                                            " :dir="$store.state.appConfig.isRTL
                                              ? 'rtl'
                                              : 'ltr'
                                              " :options="warrantyOptions" :selectable="(option) =>
                                                !option.value.includes(
                                                  'select_value'
                                                )
                                                " label="text" />
                                          <small class="text-danger">
                                            {{ errors[0] }}
                                          </small>
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                    <!-- warranty duration -->
                                    <b-col md="6" v-if="
                                      warrantySelect[index] != null &&
                                      warrantySelect[index].value == 'seller'
                                    ">
                                      <validation-provider #default="{ errors }" name="Warranty Duration"
                                        rules="required">
                                        <b-form-group label="Warranty Duration" label-for="Warranty Duration" :state="errors.length > 0 ? false : null
                                          ">
                                          <v-select id="Warranty-Duration" v-model="warrantyDurationSelect[index]
                                            " :state="warrantyDurationSelect[index] ===
                                              null
                                              ? false
                                              : true
                                              " :dir="$store.state.appConfig.isRTL
                                                ? 'rtl'
                                                : 'ltr'
                                                " :options="warrantyDurationOptions" :selectable="(option) =>
                                                !option.value.includes(
                                                  'select_value'
                                                )
                                                " label="text" />
                                          <small class="text-danger">
                                            {{ errors[0] }}
                                          </small>
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                    <b-col md="6" v-else-if="
                                      warrantySelect[index] != null &&
                                      (warrantySelect[index].value ==
                                        'agency' ||
                                        warrantySelect[index].value ==
                                        'supplier')
                                    ">
                                      <validation-provider #default="{ errors }" name="End Date" rules="required">
                                        <b-form-group label="End Date" label-for="End date" :state="errors.length > 0 ? false : null
                                          ">
                                          <flat-pickr v-model="item.warranty.end_date" class="form-control" />
                                          <small class="text-danger">
                                            {{ errors[0] }}
                                          </small>
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                    <b-col md="6" v-if="
                                      warrantySelect[index] != null &&
                                      (warrantySelect[index].value ==
                                        'agency' ||
                                        warrantySelect[index].value ==
                                        'supplier')
                                    ">
                                      <b-form-group label="Warranty file" label-for="Warranty file">
                                        <input type="file" class="form-control-file" multiple="multiple"
                                          name="Warranty_file" id="Warranty_file" ref="Warranty_file" change
                                          @change="fileUpload2($event, index)" accept="application/pdf, image/*" />
                                      </b-form-group>
                                    </b-col>
                                    <b-col md="6">
                                      <validation-provider #default="{ errors }" name="Tags" rules="required">
                                        <b-form-group label="Tags" label-for="Tags" :state="errors.length > 0 ? false : null
                                          ">
                                          <v-select id="Tags" multiple v-model="tagSelect" :state="tagSelect === [] ? false : true
                                            " :dir="$store.state.appConfig.isRTL
                                              ? 'rtl'
                                              : 'ltr'
                                              " :options="tagOptions" :selectable="(option) =>
                                                !option.value.includes(
                                                  'select_value'
                                                )
                                                " label="text" />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                    <!-- Accessories -->
                                    <b-col md="6">
                                      <validation-provider #default="{ errors }" name="Accessories" rules="required">
                                        <b-form-group label="Accessories" label-for="Accessories" :state="errors.length > 0 ? false : null
                                          ">
                                          <v-select multiple id="Accessories" mu v-model="accessoriesSelect" :state="accessoriesSelect === null
                                            ? false
                                            : true
                                            " :dir="$store.state.appConfig.isRTL
                                              ? 'rtl'
                                              : 'ltr'
                                              " :options="accessoriesOptions" :selectable="(option) =>
                                                !option.value.includes(
                                                  'select_value'
                                                )
                                                " label="text" />
                                          <small class="text-danger">
                                            {{ errors[0] }}
                                          </small>
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                    <!-- Status -->
                                    <b-col md="6">
                                      <validation-provider #default="{ errors }" name="status" rules="required">
                                        <b-form-group label="Status" label-for="Status" :state="errors.length > 0 ? false : null
                                          ">
                                          <v-select id="status" :disabled="!authPermissions.includes(
                                            'update-product-status'
                                          )
                                            " v-model="statusSelect" :state="statusSelect === null
                                              ? false
                                              : true
                                              " :dir="$store.state.appConfig.isRTL
                                                ? 'rtl'
                                                : 'ltr'
                                                " :options="statusOptions" :selectable="(option) =>
                                                !option.value.includes(
                                                  'select_value'
                                                )
                                                " label="text" />
                                          <small class="text-danger">
                                            {{ errors[0] }}
                                          </small>
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                <b-col cols="12">
                                  <hr />
                                </b-col>
                                <b-col cols="12">
                                  <b-row>
                                    <b-col cols="1" class="d-flex justify-content-center align-items-center">
                                      <b-img src="@/assets/images/ProductImages/MobileSides.png"></b-img>
                                    </b-col>
                                    <b-col cols="11">
                                      <b-form-group class="block" label="Video (.mp4)" label-for="Video">
                                        <b-form-file type="file" class="form-control-file" multiple="multiple"
                                          name="file" id="file" ref="file" change @change="fileUpload"
                                          :state="Boolean(files)" accept="video/*" />
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                <b-col cols="12">
                                  <hr />
                                </b-col>
                                <b-col cols="12">
                                  <b-row>
                                    <b-col>
                                      <b-row>
                                        <b-col cols="12">
                                          <div>
                                            <b-form ref="vImagesform" :style="{
                                              height: trHeight,
                                            }" class="repeater-form" @submit.prevent="
                                              vImagesRepeateAgain(item)
                                              ">
                                              <!-- Row Loop -->
                                              <b-row v-for="(
                                                  im, ind
                                                ) in item.vimages2" :id="im + ind" :key="im + ind" ref="row">
                                                <!-- Image Reapeted -->
                                                <b-col v-if="ind == 0" cols="1"
                                                  class="d-flex justify-content-center align-items-center">
                                                  <b-img src="@/assets/images/ProductImages/Face.png"></b-img>
                                                </b-col>
                                                <b-col v-if="ind == 1" cols="1"
                                                  class="d-flex justify-content-center align-items-center">
                                                  <b-img src="@/assets/images/ProductImages/Back.png"></b-img>
                                                </b-col>
                                                <b-col v-if="ind == 2" cols="1"
                                                  class="d-flex justify-content-center align-items-center">
                                                  <b-img src="@/assets/images/ProductImages/Bottom.png"></b-img>
                                                </b-col>
                                                <b-col v-if="ind == 3" cols="1"
                                                  class="d-flex justify-content-center align-items-center">
                                                  <b-img src="@/assets/images/ProductImages/Top.png"></b-img>
                                                </b-col>
                                                <b-col v-if="ind == 4" cols="1"
                                                  class="d-flex justify-content-center align-items-center">
                                                  <b-img src="@/assets/images/ProductImages/LeftSide.png"></b-img>
                                                </b-col>
                                                <b-col v-if="ind == 5" cols="1"
                                                  class="d-flex justify-content-center align-items-center">
                                                  <b-img src="@/assets/images/ProductImages/RightSide.png"></b-img>
                                                </b-col>
                                                <b-col v-if="ind == 6" cols="1"
                                                  class="d-flex justify-content-center align-items-center">
                                                  <b-img src="@/assets/images/ProductImages/Group521.png"></b-img>
                                                </b-col>
                                                <b-col cols="4" class="mt-auto mb-auto">
                                                  <b-form-group label="Image" :label-for="'image' + ind">
                                                    <b-form-file @change="
                                                      uploadImage3(
                                                        $event,
                                                        item,
                                                        ind
                                                      )
                                                      " v-model="item.vimages3[ind].vname
                                                        " :id="'image' +
                                                          ind +
                                                          index +
                                                          ind
                                                          " accept="image/*" />
                                                  </b-form-group>
                                                </b-col>
                                                <b-col md="2">
                                                  <b-media class="mb-1 mr-auto">
                                                    <b-img v-if="
                                                      item.vimages2[ind]
                                                        .vname != null &&
                                                      item.vimages2[ind]
                                                        .vname != ''
                                                    " class="img-thumbnail" ref="previewEl" :src="item.vimages2[ind].vname
                                                      " height="100px" />
                                                  </b-media>
                                                </b-col>
                                                <!-- Remove Button -->
                                                <b-col cols="3" class="mt-auto mb-auto">
                                                  <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'
                                                    " v-if="ind > 6" variant="outline-danger" class="mt-0 mt-md-2"
                                                    @click="
                                                      vImagesRemoveItem(
                                                        ind,
                                                        item
                                                      )
                                                      ">
                                                    <feather-icon icon="XIcon" class="mr-25" />
                                                    <span>Remove</span>
                                                  </b-button>
                                                </b-col>
                                                <b-col cols="12">
                                                  <hr />
                                                </b-col>
                                              </b-row>
                                            </b-form>
                                          </div>
                                        </b-col>
                                        <!-- add new button -->
                                        <b-col cols="12">
                                          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'
                                            " style="width: 40%" variant="outline-primary" block
                                            @click="vImagesRepeateAgain(item)">
                                            <feather-icon icon="PlusIcon" class="mr-25" />
                                            <span>Add New Image</span>
                                          </b-button>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col cols="12">
                              <hr />
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
                <!-- add Price Model -->
                <b-modal id="modal-prevent-closing" cancel-variant="outline-secondary" @ok="handleOk" ok-title="Add"
                  cancel-title="Close" centered title="Add Price">
                  <validation-observer ref="addPrice">
                    <b-form @submit="addPriceRate">
                      <b-row>
                        <!-- varint price -->
                        <b-col md="6">
                          <b-form-group label="Price" label-for="price">
                            <validation-provider #default="{ errors }" name="price"
                              rules="required|regex:\b\d+(?:\.\d\d?)?\b">
                              <b-form-input v-model="variantItem.voriginal_price" min="0" type="number"
                                :state="errors.length > 0 ? false : null" />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group label="Rate" label-for="rate">
                            <b-form-input v-model="variantItem.rate" min="0" type="number" />
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group label="Type" label-for="Type">
                            <v-select id="Offer-Type" v-model="typeSelect" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              " :options="offerTypeOptions" :selectable="(option) =>
                                !option.value.includes('select_value')
                                " label="text" />
                          </b-form-group>
                        </b-col>
                        <!-- varint Amount -->
                        <b-col md="6">
                          <b-form-group label="Amount" label-for="amount">
                            <b-form-input min="1" type="number" v-model="variantItem.offerBDel.amount"
                              placeholder="enter amount" />
                          </b-form-group>
                        </b-col>
                        <b-col v-if="variantItem.offerBDel.amount > 0" md="12">
                          <b-row>
                            <b-col>
                              <b-row>
                                <b-col md="6">
                                  <validation-provider #default="{ errors }" name="Offer Type" rules="required">
                                    <b-form-group label="Offer Type" label-for="Offer Type"
                                      :state="errors.length > 0 ? false : null">
                                      <v-select id="Offer-Type" v-model="offerTypeSelect" :state="offerTypeSelect === null
                                        ? false
                                        : true
                                        " :dir="$store.state.appConfig.isRTL
                                          ? 'rtl'
                                          : 'ltr'
                                          " :options="offerTypeOptions" :selectable="(option) =>
                                            !option.value.includes(
                                              'select_value'
                                            )
                                            " label="text" />
                                      <small class="text-danger">
                                        {{ errors[0] }}
                                      </small>
                                    </b-form-group>
                                  </validation-provider>
                                </b-col>
                                <b-col md="6">
                                  <b-form-group label="Users Number" label-for="User-Numbers">
                                    <validation-provider #default="{ errors }" name="user numbers"
                                      rules="required|regex:^[0]*[1-9][0-9]*$">
                                      <b-form-input min="1" id="User-Numbers" type="number" v-model="variantItem.offerBDel.usernumbers
                                        " :state="errors.length > 0 ? false : null
                                          " placeholder="Users Number" />
                                      <small class="text-danger">
                                        {{ errors[0] }}
                                      </small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="6">
                                  <validation-provider #default="{ errors }" name="start date" rules="required">
                                    <b-form-group label="Start Date" label-for="start date"
                                      :state="errors.length > 0 ? false : null">
                                      <flat-pickr v-model="variantItem.offerBDel.startdate
                                        " class="form-control" :config="{
                                          enableTime: true,
                                          dateFormat: 'Y-m-d',
                                          minDate: 'today',
                                        }" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null
                                        ">
                                        {{ errors[0] }}
                                      </b-form-invalid-feedback>
                                    </b-form-group>
                                  </validation-provider>
                                </b-col>
                                <b-col md="6">
                                  <validation-provider #default="{ errors }" name="end date" rules="required">
                                    <b-form-group label="End Date" label-for="end date"
                                      :state="errors.length > 0 ? false : null">
                                      <flat-pickr v-model="variantItem.offerBDel.enddate" :disabled="variantItem.offerBDel.startdate ==
                                        null ||
                                        variantItem.offerBDel.startdate == ''
                                        " class="form-control" :config="{
                                          enableTime: true,
                                          dateFormat: 'Y-m-d',
                                          minDate:
                                            variantItem.offerBDel.startdate,
                                        }" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null
                                        ">
                                        {{ errors[0] }}
                                      </b-form-invalid-feedback>
                                    </b-form-group>
                                  </validation-provider>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                  <!-- error handelr -->
                  <b-alert v-height-fade.appear fade :show="showDismissibleAlert2"
                    @dismissed="showDismissibleAlert2 = false" variant="danger">
                    <h4 class="alert-heading">Alert</h4>
                    <div class="alert-body">
                      <ul v-for="(values, index) in errors_back2" :key="index">
                        <li v-for="(value, indexVal) in values" :key="indexVal">
                          {{ value }}
                        </li>
                      </ul>
                    </div>
                  </b-alert>
                </b-modal>
              </b-card>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
    <!-- error handelr -->
    <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
      variant="danger">
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'

import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import { codeIcon } from './code2'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    FormWizard,
    TabContent,

    flatPickr,
    Cleave,
  },
  mixins: [heightTransition],
  watch: {
    'productData.serialnumber': function () {
      this.currentPage = 0
      this.serialsNumber.length = 0
      this.itemSelect = null
    },
    brandSelect: function () {
      this.brendSe = true
      this.itemSelect = null
      if (this.brandSelect != null) {
        this.getItems()
      }
    },
    itemSelect: function () {
      if (this.itemSelect != null) {
        this.getAttrValue()
      }
    },
    attrSelect: function () {
      this.getOptions()
    },
    valuesSelect: function () { },
  },
  data() {
    return {
      offerTypeOptions: [
        {
          text: 'Fixed Price',
          value: 'fixed_price',
        },
        {
          text: 'Percentage',
          value: 'percentage',
        },
      ],
      typeSelect: null,
      authPermissions: [],
      vendorSelect: {
        text: '',
        value: '',
      },
      statusSelect: null,
      statusOptions: [
        // {
        //   value: 'select_value',
        //   text: 'Select Value',
        // },
        // {
        //   value: 'enabled',
        //   text: 'Enabled',
        // },
        // {
        //   value: 'disabled',
        //   text: 'Disabled',
        // },
        {
          value: 'review',
          text: 'Review',
        },
        // {
        //   value: 'rejected',
        //   text: 'Rejected',
        // },
      ],
      errors_back: [],
      showDismissibleAlert: false,
      errors_back2: [],
      showDismissibleAlert2: false,
      required,
      itemSe: true,
      subActive: true,
      itemSe: true,
      offerTypeSelect: null,
      offerTypeOptions: [
        {
          text: 'Fixed Price',
          value: 'fixed_price',
        },
        {
          text: 'Percentage',
          value: 'percentage',
        },
      ],
      codeIcon,
      noResult: false,
      resultShow: false,
      productData: {
        serialnumber: null,
        is_new: 'used',
        category_id: '',
        brand_id: '',
        status: '',
        item_id: '',
        status: '',
        tags: [],
        translations: [
          {
            locale: '',
            name: '',
            short_description: '',
            description: '',
          },
          {
            locale: '',
            name: '',
            short_description: '',
            description: '',
          },
        ],
        attributes: [],
        variants: [],
      },
      currentPage: 0,
      serialsNumber: [],
      brandSelect: null,
      brandOptions: [],
      itemSelect: null,
      itemOptions: [],
      brendSe: true,
      categorySelect: null,
      categoryOptions: [],
      tagSelect: [],
      tagOptions: [],
      accessoriesSelect: null,
      accessoriesOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      valuesSelect: [],
      valuesOptions: [
        [
          {
            value: 'select_value',
            text: 'Select Value',
          },
        ],
      ],
      valuesID: [],
      combTextArr: [[]],
      combValuesArr: [[]],
      varints: [],
      attrSelect: [],
      attrOptions: [],
      attr2Options: [],
      files: null,
      warrantyOptions: [
        {
          text: 'Seller',
          value: 'seller',
        },
        {
          text: 'Agency',
          value: 'agency',
        },
        {
          text: 'Supplier',
          value: 'supplier',
        },
      ],
      warrantyDurationSelect: [],
      warrantyDurationOptions: [
        {
          text: '7 يوم',
          value: '7',
        },
        {
          text: '14 يوم',
          value: '14',
        },
        {
          text: '30 يوم',
          value: '30',
        },
      ],
      warrantySelect: [],
      variantItem: {
        offerHide: false,
        offerBDel: {
          type: null,
          amount: '',
          startdate: null,
          enddate: null,
          usernumbers: null,
        },
        rate: null,
        type: null,
        voriginal_price: '',
      },
    }
  },
  methods: {
    formSubmitted() {
      this.showDismissibleAlert = false
      this.productData.item_id = this.itemSelect.value
      this.productData.status = this.statusSelect.value
      for (let index in this.warrantySelect) {
        this.varints[index].warranty.type = this.warrantySelect[index].value
        if (this.varints[index].warranty.type == 'seller') {
          this.varints[index].warranty.duration =
            this.warrantyDurationSelect[index].value
        }
      }
      this.productData.accessories = []
      for (let accessory in this.accessoriesSelect) {
        this.productData.accessories.push(
          this.accessoriesSelect[accessory].value
        )
      }
      this.productData.tags = []
      for (let index in this.tagSelect) {
        this.productData.tags.push(this.tagSelect[index].value)
      }
      for (let vari in this.varints) {
        const datav = this.varints[vari].vtranslations
        for (let tran in datav) {
          datav[tran].vlocale = datav[tran].vlocale1.value
        }
      }
      for (let vari in this.varints) {
        const varint = this.varints[vari]
        // if(varint.vimages2 != null){
        for (let img in varint.vimages2) {
          if (this.isString(varint.vimages2[img].vname)) {
            if (varint.vimages2[img].vname.includes(',')) {
              varint.vimages[img].vname = null
              var imageC = varint.vimages2[img].vname
                .split(',')
                .map((item) => item.trim())
              varint.vimages[img].vname = imageC[1]
            }
          }
        }
        // }
      }
      for (let vari in this.varints) {
        const varint = this.varints[vari]
        if (varint.values.includes('-')) {
          varint.values = varint.values.split('-').map((item) => item.trim())
        }
      }
      for (let off in this.varints) {
        if (
          this.varints[off].voriginal_price == null ||
          this.varints[off].voriginal_price == ''
        ) {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error! (Check varaint price)',
            showConfirmButton: false,
            timer: 1500,
          })
          return
        }
        if (this.varints[off].offerBDel.amount == '') {
          delete this.varints[off].offer
        } else {
          this.varints[off].offer.amount = this.varints[off].offerBDel.amount
          this.varints[off].offer.type = this.varints[off].offerBDel.type
          this.varints[off].offer.startdate =
            this.varints[off].offerBDel.startdate
          this.varints[off].offer.enddate = this.varints[off].offerBDel.enddate
          this.varints[off].offer.usernumbers =
            this.varints[off].offerBDel.usernumbers
        }
      }
      this.productData.variants = this.varints
      const Product_data = this.productData

      let formData = new FormData()
      if (this.files != null) {
        formData.append('video', this.files)
      }
      for (var i = 0; i < Product_data.accessories.length; i++) {
        formData.append('accessories[' + i + ']', Product_data.accessories[i])
      }
      formData.append('is_inactive', 0)
      formData.append('is_portal', 1)
      formData.append('item_id', Product_data.item_id)
      formData.append('is_new', Product_data.is_new)
      formData.append('vendor_id', this.vendorSelect.value)
      formData.append('status', Product_data.status)
      formData.append('vendor_id', this.vendorSelect.value)
      formData.append('category_id',this.categorySelect.value)

      for (var i = 0; i < Product_data.attributes.length; i++) {
        formData.append(
          'attributes[' + i + '][id]',
          Product_data.attributes[i].id
        )
        for (var z = 0; z < Product_data.attributes[i].values.length; z++) {
          formData.append(
            'attributes[' + i + '][values][' + z + ']',
            Product_data.attributes[i].values[z]
          )
        }
      }
      // formData.append('variants', Product_data.variants)
      for (var i = 0; i < Product_data.variants.length; i++) {
        formData.append(
          'variants[' + i + '][voriginal_price]',
          Product_data.variants[i].voriginal_price
        )
        if (
          Product_data.variants[i].rate != null ||
          Product_data.variants[i].type != null
        ) {
          formData.append(
            'variants[' + i + '][rate]',
            Product_data.variants[i].rate
          )
          formData.append(
            'variants[' + i + '][type]',
            Product_data.variants[i].type
          )
        }
        for (var z = 0; z < this.tagSelect.length; z++) {
          formData.append('tags[' + z + ']', this.tagSelect[z].value)
        }
        formData.append(
          'variants[' + i + '][vquantity]',
          Product_data.variants[i].vquantity
        )
        formData.append(
          'variants[' + i + '][vis_new]',
          Product_data.variants[i].vis_new
        )
        formData.append(
          'variants[' + i + '][warranty][type]',
          Product_data.variants[i].warranty.type
        )
        if (Product_data.variants[i].warranty.type == 'seller') {
          formData.append(
            'variants[' + i + '][warranty][duration]',
            Product_data.variants[i].warranty.duration
          )
        } else {
          formData.append(
            'variants[' + i + '][warranty][end_date]',
            Product_data.variants[i].warranty.end_date
          )
          formData.append(
            'variants[' + i + '][warranty][agency_file]',
            Product_data.variants[i].warranty.agency_file
          )
        }
        if (this.productData.is_new == 'used') {
          formData.append(
            'variants[' + i + '][report_serial_number]',
            Product_data.serialnumber
          )
        }
        if (Product_data.variants[i].vimages != null) {
          for (var z = 0; z < Product_data.variants[i].vimages.length; z++) {
            formData.append(
              'variants[' + i + '][vimages][' + z + '][vname]',
              Product_data.variants[i].vimages[z].vname
            )
          }
        }
        for (var z = 0; z < Product_data.variants[i].values.length; z++) {
          formData.append(
            'variants[' + i + '][values][' + z + ']',
            Product_data.variants[i].values[z]
          )
        }
        // for (var z = 0; z < Product_data.variants[i].vtranslations.length; z++) {
        //     formData.append("variants[" + i + "][vtranslations][" + z + "][vlocale]", Product_data.variants[i].vtranslations[z].vlocale);
        //     formData.append("variants[" + i + "][vtranslations][" + z + "][vshort_description]", Product_data.variants[i].vtranslations[z].vshort_description);
        //     formData.append("variants[" + i + "][vtranslations][" + z + "][vdescription]", Product_data.variants[i].vtranslations[z].vdescription);
        // }
        if (
          this.varints[i].offerBDel.amount != 0 &&
          this.varints[i].offerBDel.amount != null &&
          this.varints[i].offerBDel.amount != ''
        ) {
          formData.append(
            'variants[' + i + '][offer][amount]',
            Product_data.variants[i].offer.amount
          )
          formData.append(
            'variants[' + i + '][offer][type]',
            Product_data.variants[i].offer.type
          )
          formData.append(
            'variants[' + i + '][offer][startdate]',
            Product_data.variants[i].offer.startdate
          )
          formData.append(
            'variants[' + i + '][offer][enddate]',
            Product_data.variants[i].offer.enddate
          )
          formData.append(
            'variants[' + i + '][offer][usernumbers]',
            Product_data.variants[i].offer.usernumbers
          )
        }
      }

      axios
        .post('products', formData)
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.$router.push({
            path: '/Used/Product/Index',
          })
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    setPrice(item, index) {
      this.errors_back2 = []
      this.showDismissibleAlert2 = false
      this.variantItem = item
      this.variantIndex = index
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.addPriceRate()
    },
    async addPriceRate() {
      this.showDismissibleAlert2 = false
      const isValid = await this.$refs.addPrice.validate()
      if (isValid) {
        var sendData = null
        if (
          this.variantItem.offerBDel.amount == 0 ||
          this.variantItem.offerBDel.amount == null ||
          this.variantItem.offerBDel.amount == ''
        ) {
          sendData = {
            original_price: this.variantItem.voriginal_price,
            rate: this.variantItem.rate,
            type: this.typeSelect == null ? null : this.typeSelect.value,
            item_id: this.itemSelect.value,
            vendor_id: this.vendorSelect.value,
            product_type: 'used',
            is_portal: true
          }
        } else {
          sendData = {
            original_price: this.variantItem.voriginal_price,
            rate: this.variantItem.rate,
            type: this.typeSelect == null ? null : this.typeSelect.value,
            item_id: this.itemSelect.value,
            offer_type:
              this.offerTypeSelect == null ? null : this.offerTypeSelect.value,
            offer_amount:
              this.variantItem.offerBDel.amount == 0
                ? null
                : this.variantItem.offerBDel.amount,
            vendor_id: this.vendorSelect.value,
            product_type: 'used',
            is_portal: true
          }
        }
        if (sendData.rate == null || sendData.type == null) {
          delete sendData.rate
          delete sendData.type
        }
        axios
          .post('products/sale-price/get', sendData)
          .then((result) => {
            this.varints[this.variantIndex].offerBDel.amount =
              this.variantItem.offerBDel.amount == 0
                ? null
                : this.variantItem.offerBDel.amount
            this.varints[this.variantIndex].offerBDel.type =
              this.offerTypeSelect == null ? null : this.offerTypeSelect.value
            this.varints[this.variantIndex].voriginal_price =
              this.variantItem.voriginal_price
            this.varints[this.variantIndex].offerBDel.startdate =
              this.variantItem.offerBDel.startdate == null
                ? null
                : this.variantItem.offerBDel.startdate
            this.varints[this.variantIndex].offerBDel.enddate =
              this.variantItem.offerBDel.enddate == null
                ? null
                : this.variantItem.offerBDel.enddate
            this.varints[this.variantIndex].offerBDel.usernumbers =
              this.variantItem.offerBDel.usernumbers == null
                ? null
                : this.variantItem.offerBDel.usernumbers
            this.varints[this.variantIndex].profit = result.data.profit
            this.varints[this.variantIndex].salePrice = result.data.sale_price
            if (this.variantItem.rate != null && this.typeSelect != null) {
              this.varints[this.variantIndex].rate = this.variantItem.rate
              this.varints[this.variantIndex].type = this.typeSelect.value
            }

            this.$nextTick(() => {
              this.$bvModal.hide('modal-prevent-closing')
            })
          })
          .catch((err) => {
            this.errors_back2.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back2.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back2 = err.response.data.data
              }
              this.showDismissibleAlert2 = true
            } else {
              this.errors_back2 = []
              this.errors_back2.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert2 = true
            }
          })
      } else {
      }
    },
    getAttrValue() {
      this.errors_back = []
      this.showDismissibleAlert = false
      axios
        .get(
          'products/items/' +
          this.itemSelect.value +
          '/reports/' +
          this.productData.serialnumber +
          '/valid-data'
        )
        .then((result) => {
          const data = result.data.data.attributes
          const item = result.data.data.item

          this.attrOptions.length = 0
          this.vendorSelect.text = result.data.data.vendor.storename
          this.vendorSelect.value = result.data.data.vendor.id
          this.attrSelect.length = 0
          this.productData.attributes.length = 0
          this.valuesOptions.length = 0
          this.combTextArr.length = 0
          this.combValuesArr.length = 0
          this.attr2Options = null
          if (data.length > 0) {
            for (let attr in data) {
              if (data[attr].translation != null) {
                this.attrOptions.push({
                  value: data[attr].id.toString(),
                  text: data[attr].translation.name,
                  slug: data[attr].translation.slug,
                })
                this.attrSelect.push({
                  value: data[attr].id.toString(),
                  text: data[attr].translation.name,
                  slug: data[attr].translation.slug,
                })
                this.productData.attributes.push({
                  id: '',
                  values: [],
                })

                this.valuesOptions.push([])
                for (let val in data[attr].values) {
                  this.valuesOptions[attr].push({
                    text: data[attr].values[val].translation.name,
                    value: data[attr].values[val].id.toString(),
                  })
                }
                if (
                  data[attr].values.filter((e) => e.valid === true).length > 0
                ) {
                  for (let val in data[attr].values) {
                    if (data[attr].values[val].valid == true) {
                      this.valuesSelect.push({
                        text: data[attr].values[val].translation.name,
                        value: data[attr].values[val].id.toString(),
                      })
                    }
                  }
                } else {
                  this.valuesSelect.push(null)
                }
              }
              this.combTextArr.push([])
              this.combValuesArr.push([])
            }
            this.attr2Options = this.attrOptions
              ; (this.categorySelect = {
                text: item.category.translation.name,
                value: item.category.id.toString(),
              }),
                (this.itemSelect.name = item.item_translations.name),
                (this.itemSelect.description =
                  item.item_translations.description),
                (this.itemSelect.short_description =
                  item.item_translations.short_description),
                (this.itemSelect.status = item.status),
                (this.itemSelect.cover = item.cover)
          }
        })
        .catch((err) => {
          console.log(err)
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    generate() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules2.validate().then((success) => {
          if (success) {


            for (let val in this.valuesSelect) {
              const vale = this.valuesSelect[val]

              this.productData.attributes[val].values = []
              if (this.productData.is_new == 'used') {
                this.productData.attributes[val].values.push(vale.value)
              } else {


                for (let valID in vale) {
                  this.productData.attributes[val].values.push(
                    vale[valID].value
                  )
                }


              }
            }

            console.log("product", this.productData)


            for (let att in this.productData.attributes) {
              const attrbu = this.productData.attributes[att]
              attrbu.id = this.attrSelect[att].value
            }



            console.log("combTextArr", this.combTextArr)

            for (let val in this.valuesSelect) {
              const vale = this.valuesSelect[val]

              console.log("product 222", this.combTextArr[val])
              this.combTextArr[val].length = 0
              if (this.productData.is_new == 'used') {
                this.combTextArr[val].push(vale.text)
              } else {
                for (let valID in vale) {
                  this.combTextArr[val].push(vale[valID].text)
                }
              }
            }

            for (let val in this.valuesSelect) {
              const vale = this.valuesSelect[val]
              this.combValuesArr[val] = []
              if (this.productData.is_new == 'used') {
                this.combValuesArr[val].push(vale.value)
              } else {
                for (let valID in vale) {
                  this.combValuesArr[val].push(vale[valID].value)
                }
              }
            }

            
            console.log("this.combTextArr", this.combTextArr)
            console.log("this.combValuesArr", this.combValuesArr)
            const compainText = this.getCombn(this.combTextArr)
            const compainValues = this.getCombn2(this.combValuesArr)
            console.log("compainValues", compainValues)
            for (let tran in this.varints) {
              this.varints[tran].vis_new = this.productData.is_new
            }
            this.subActive = false
          } else {
            reject()
          }
        })
      })
    },
    // make combination to get names of variants
    getCombn(arr) {
      if (arr.length == 1) {
        var ans = []
        for (let ind in arr[0]) {
          ans.push(arr[0][ind])
        }
        this.warrantySelect.length = 0
        this.warrantyDurationSelect.length = 0
        this.varints.length = 0
        for (let inde in ans) {
          this.warrantySelect.push()
          this.warrantyDurationSelect.push()
          this.varints.push({
            vname: ans[inde],
            vquantity: '1',
            vis_new: 'used',
            rate: null,
            type: null,
            warranty: {
              type: null,
              duration: null,
              end_date: null,
              agency_file: null,
            },
            voriginal_price: '',
            salePrice: '',
            amountPriceDis: true,
            report_serial_number: '',
            vtranslations: [
              {
                vlocale1: {
                  text: 'English',
                  value: 'en',
                  disa: true,
                },
                vlocale: '',
                vdescription: '',
                vshort_description: '',
              },
              {
                vlocale1: {
                  text: 'Arabic',
                  value: 'ar',
                  disa: true,
                },
                vlocale: '',
                vdescription: '',
                vshort_description: '',
              },
            ],
            offerHide: false,
            offerBDel: {
              type: 'fixed_price',
              amount: '',
              startdate: '',
              enddate: '',
              usernumbers: '',
            },
            offer: {
              type: 'fixed_price',
              amount: '',
              startdate: '',
              enddate: '',
              usernumbers: '',
            },
            vimages: [
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
            ],
            vimages3: [
              {
                vname: [],
              },
              {
                vname: [],
              },
              {
                vname: [],
              },
              {
                vname: [],
              },
              {
                vname: [],
              },
              {
                vname: [],
              },
              {
                vname: [],
              },
            ],
            vimages2: [
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
            ],
          })
        }
        return ans
      } else {
        var ans = []
        // recur with the rest of the array.
        var otherCases = this.getCombn(arr.slice(1))
        for (var i = 0; i < otherCases.length; i++) {
          for (var j = 0; j < arr[0].length; j++) {
            ans.push(arr[0][j] + '-' + otherCases[i])
          }
        }
        this.warrantySelect.length = 0
        this.varints.length = 0
        this.warrantyDurationSelect.length = 0
        for (let ind in ans) {
          this.warrantySelect.push()
          this.warrantyDurationSelect.push()
          this.varints.push({
            vname: ans[ind],
            vquantity: '1',
            voriginal_price: '',
            salePrice: '',
            vis_new: 'used',
            rate: null,
            type: null,
            warranty: {
              type: null,
              duration: null,
              end_date: null,
              agency_file: null,
            },
            amountPriceDis: true,
            report_serial_number: '',
            vtranslations: [
              {
                vlocale1: {
                  text: 'English',
                  value: 'en',
                  disa: true,
                },
                vlocale: '',
                vdescription: '',
                vshort_description: '',
              },
              {
                vlocale1: {
                  text: 'Arabic',
                  value: 'ar',
                  disa: true,
                },
                vlocale: '',
                vdescription: '',
                vshort_description: '',
              },
            ],
            offerHide: false,
            offerBDel: {
              type: 'fixed_price',
              amount: '',
              startdate: '',
              enddate: '',
              usernumbers: '',
            },
            offer: {
              type: 'fixed_price',
              amount: '',
              startdate: '',
              enddate: '',
              usernumbers: '',
            },
            vimages: [
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
            ],
            vimages3: [
              {
                vname: [],
              },
              {
                vname: [],
              },
              {
                vname: [],
              },
              {
                vname: [],
              },
              {
                vname: [],
              },
              {
                vname: [],
              },
              {
                vname: [],
              },
            ],
            vimages2: [
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
              {
                vname: '',
              },
            ],
          })
        }
        return ans
      }
    },
    // make combinations to get values of variants
    getCombn2(arr) {
        console.log("inside " , arr)
      if (arr.length == 1) {
        var ans = []
        for (let ind in arr[0]) {
          ans.push(arr[0][ind])
        }
        console.log('varints' , this.varints)
        for (let inde in ans) {
          console.log('inde' , inde)
          console.log('ans' , ans[inde])
          console.log('varints' , this.varints[inde])
          this.varints[inde].values = [ans[inde]]
          console.log('varints' , this.varints[inde])
        }
        return ans
      } else {
        var ans = []
        // recur with the rest of the array.
        var otherCases = this.getCombn2(arr.slice(1))
        for (var i = 0; i < otherCases.length; i++) {
          for (var j = 0; j < arr[0].length; j++) {
            ans.push(arr[0][j] + '-' + otherCases[i])
          }
        }
        for (let ind in ans) {
          this.varints[ind].values = ans[ind]
        }
        return ans
      }
    },
    // get values
    getValues(index, id) {
      axios
        .get('products/attribute/' + id + '/get-values')
        .then((result) => {
          this.valuesOptions[index].length = 0
          this.valuesSelect[index] = null
          let data = result.data.data
          for (let ind in data) {
            if (data[ind].translation != null) {
              this.valuesOptions[index].push({
                value: data[ind].id.toString(),
                text: data[ind].translation.name,
              })
            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // uploade video
    fileUpload(e) {
      this.files = e.target.files[0]
    },
    fileUpload2(event, index) {
      this.varints[index].warranty.agency_file = event.target.files[0]
    },
    // set attrbuites
    getOptions() {
      this.attr2Options = this.attrOptions.filter(
        (item) => !this.attrSelect.includes(item)
      )
    },
    getAccessories() {
      axios
        .get('accessories')
        .then((result) => {
          const data = result.data.data
          for (let index in data) {
            if (data[index].translation != null) {
              this.accessoriesOptions.push({
                text: data[index].translation.name,
                value: data[index].id.toString(),
              })
            } else {
              this.accessoriesOptions.push({
                text: 'undefinded',
                value: data[index].id.toString(),
              })
            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // upload function vimages of item
    uploadImage3(event, item, index) {
      let files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        return
      } else {
        this.createImage3(files[0], index, item)
      }
    },
    createImage3(file, ind, item) {
      let reader = new FileReader()
      reader.onload = (e) => {
        item.vimages2[ind].vname = e.target.result
      }
      reader.readAsDataURL(file)
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            if (this.errors_back.length == 0) {
              resolve(true)
            } else {
              reject()
            }
          } else {
            reject()
          }
        })
      })
    },
    validationForm2() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules2.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    getItems() {
      // this.itemSelect = null
      if (this.brandSelect != null) {
        this.itemOptions = [
          {
            value: 'select_value',
            text: 'Select Value',
          },
        ]
        axios
          .get('products/brands/' + this.brandSelect.value + '/items/get/' + this.$route.params.id)
          .then((result) => {
            const data = result.data.data
            this.itemOptions.lenght = 0
            this.itemOptions = data
            this.brendSe = false
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      }
    },
    searchResult($state) {
      if (this.productData.serialnumber.length >= 5) {
        this.showDismissibleAlert = false
        this.currentPage += 1
        // request Brand index API
        var url =
          'diagnostic/store-product/reports-pagi/10/sortBy-id-desc/' +
          this.productData.serialnumber +
          '?page=' +
          this.currentPage
        axios
          .get(url)
          .then((result) => {
            const data = result.data.data.data
            if (data.length > 0) {
              for (let index in data) {
                this.serialsNumber.push({
                  name: data[index].serial_number,
                })
              }
              if (this.serialsNumber.length > 0) {
                this.resultShow = true
              } else {
                this.noResult = true
                this.resultShow = false
              }
              if (this.serialsNumber.length > 10) {
                $state.loaded()
              } else {
                $state.complete()
              }
            } else {
              $state.complete()
            }
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      }
    },
    searchResultButton($state) {
      // if (this.productData.serialnumber.length >= 5) {
      this.showDismissibleAlert = false
      this.serialsNumber.length = 0
      this.currentPage = 1
      if (this.productData.serialnumber != null) {
        // request Brand index API
        var url =
          'diagnostic/store-product/reports-pagi/10/sortBy-id-desc/' +
          this.productData.serialnumber +
          '?page=' +
          this.currentPage
      } else {
        var url =
          'diagnostic/store-product/reports-pagi/10/sortBy-id-desc/' +
          '?page=' +
          this.currentPage
      }
      axios
        .get(url)
        .then((result) => {
          const data = result.data.data.data
          if (data.length > 0) {
            for (let index in data) {
              this.serialsNumber.push({
                name: data[index].serial_number,
              })
            }
            if (this.serialsNumber.length > 0) {
              this.resultShow = true
            } else {
              this.noResult = true
              this.resultShow = false
            }
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
      // }
    },
    vImagesRepeateAgain(item) {
      //  this.refheightForm = this.$refs.pricesForm
      item.vimages.push({
        vname: '',
      })
      item.vimages2.push({
        name: 'Image',
        vname: '',
      })
      item.vimages3.push({
        vname: [],
      })
    },
    vImagesRemoveItem(index, item) {
      if (item.vimages2.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        item.vimages2.splice(index, 1)
        item.vimages3.splice(index, 1)
        item.vimages.splice(index, 1)
      }
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    if (!this.authPermissions.includes('update-product-status')) {
      this.statusSelect = {
        value: 'review',
        text: 'Review',
      }
    }
    this.getAccessories()

    axios
      .get('tags')
      .then((result) => {
        const data = result.data.data
        // set Brands
        if (data.length > 0) {
          for (let ind in data) {
            this.tagOptions.push({
              text: data[ind].name,
              value: data[ind].id.toString(),
            })
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })

    axios
      .get('products/create')
      .then((result) => {
        const data = result.data.data
        // set Brands
        if (data.brands.length > 0) {
          for (let ind in data.brands) {
            this.brandOptions.push({
              text: data.brands[ind].name,
              value: data.brands[ind].id.toString(),
            })
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';

#vs9__listbox {
  z-index: 99999 !important;
}

[dir='rtl'] .vs__selected-options {
  direction: rtl !important;
}

.vs__selected {
  color: black !important;
}

.vertical.wizard-vertical.vue-form-wizard .wizard-navigation .wizard-nav {
  min-width: 75px !important;
}
</style>
